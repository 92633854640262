<template>
  <div>
    <a-table
      :scroll="{ x: 'max-content' }"
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findSaleAgentMonthlyStorePerformances } from '@/api/sale_monthly_performance'
import { formatCurrency } from '@/utils/filter'

export default {
  name: 'SaleMonthlyPerformanceInfoStoredList',
  components: {
    Pagination
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '客户名称',
          dataIndex: 'agent_name'
        },
        {
          title: '账单类型',
          dataIndex: 'bill_type'
        },
        {
          title: '项目批次',
          dataIndex: 'agent_bill_no'
        },
        {
          title: '账单金额(元)',
          customRender: formatCurrency,
          dataIndex: 'amount'
        },
        {
          title: '卡数',
          dataIndex: 'cards_count'
        },
        {
          title: '账单时间',
          dataIndex: 'agent_bill_time'
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findSaleAgentMonthlyStorePerformances(this.id, this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>

