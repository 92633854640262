<template>
  <div>
    <a-row :gutter="[8,16]">
      <a-col>
        <div class="simiot-card-box">
          <sale-monthly-performance-info-statistic :id="id" />
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="[8,16]">
      <a-col>
        <div class="simiot-card-box">
          <a-tabs default-active-key="additional">
            <a-tab-pane key="additional" tab="新增业绩">
              <sale-monthly-performance-info-additional-list :id="id" />
            </a-tab-pane>
            <a-tab-pane key="stored" tab="存量业绩">
              <sale-monthly-performance-info-stored-list :id="id" />
            </a-tab-pane>
          </a-tabs>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import SaleMonthlyPerformanceInfoStatistic from '@/views/sale_monthly_performances/info/Statistic'
import SaleMonthlyPerformanceInfoAdditionalList from '@/views/sale_monthly_performances/info/AdditionalList'
import SaleMonthlyPerformanceInfoStoredList from '@/views/sale_monthly_performances/info/StoredList'

export default {
  name: 'SaleMonthlyPerformanceShow',
  components: {
    SaleMonthlyPerformanceInfoStoredList,
    SaleMonthlyPerformanceInfoAdditionalList,
    SaleMonthlyPerformanceInfoStatistic
  },
  data() {
    return {
    }
  },
  computed: {
    id() {
      return parseInt(this.$route.params.id)
    }
  }
}
</script>

