<template>
  <a-row>
    <a-col :span="24">
      <a-descriptions
        :column="4"
        class="simiot-descriptions simiot-descriptions-max-8"
      >
        <a-descriptions-item label="新增业绩-卡板(元)">
          {{ data.additional_card_amount | formatCurrency }}
        </a-descriptions-item>

        <a-descriptions-item label="存量业绩(元)">
          {{ data.stored_amount | formatCurrency }}
        </a-descriptions-item>

        <a-descriptions-item label="新增客户数(个)">
          {{ data.new_agents_count | formatBigNumber }}
        </a-descriptions-item>

        <a-descriptions-item label="销售业绩总额(元)">
          {{ data.total_amount | formatCurrency }}
        </a-descriptions-item>

        <a-descriptions-item label="新增业绩-套餐(元)">
          {{ data.additional_product_amount | formatCurrency }}
        </a-descriptions-item>

        <a-descriptions-item label="新增卡量(张)">
          {{ data.new_cards_count | formatBigNumber }}
        </a-descriptions-item>

        <a-descriptions-item label="客户总数(个)">
          {{ data.total_agents_count | formatBigNumber }}
        </a-descriptions-item>
      </a-descriptions>
    </a-col>
  </a-row>
</template>

<script>
import { findSaleMonthlyPerformance } from '@/api/sale_monthly_performance'

export default {
  name: 'SaleMonthlyPerformanceInfoStatistic',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findSaleMonthlyPerformance(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.setBreadcrumb()
        }
      })
    },

    setBreadcrumb() {
      if (this.$store.getters.userRole === 'sale' || this.$store.getters.userRole === 'sale_assistant') {
        this.$store.dispatch('ConcatDynamicBreadCrumbs', [
          { redirect: `/sale_monthly_performances/by_month?month=${this.data.month}`, meta: { title: this.data.month }}]
        ).then(() => {})
      } else {
        this.$store.dispatch('ConcatDynamicBreadCrumbs', [
          { redirect: `/sale_monthly_performances/by_month?month=${this.data.month}`, meta: { title: this.data.month }},
          { redirect: '', meta: { title: this.data.sale_full_name }}]
        ).then(() => {})
      }
    }
  }
}
</script>
